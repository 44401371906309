import React, { useEffect, useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import axios from "axios";
import Layout from "../components/Layout";
import { Spinner } from "../components/Spinner/Spinner";
import useSessionstorage from "@rooks/use-sessionstorage";

const gStyles = require("../styles/richText.module.scss");
const styles = require("../pages/ucits/ucits.module.scss");

const API_URL =
  process.env.GATSBY_AMXCONNECT_API_URL || "https://dev.amxconnect.com";
const API_KEY = process.env.GATSBY_UCITS_API_KEY;

const url = `${API_URL}/api/UCITSFunds?apiKey=${API_KEY}`;

export default function UcitsPage({
  pageContext,
  data: {
    strapiUcits: { mainCopy },
  },
}) {
  const [data, setData] = useState({
    loading: true,
    funds: null,
    error: false,
  });

  const [ucits] = useSessionstorage("ucits", 0);

  useEffect(() => {
    const toUrl = window.location.pathname;
    if (ucits === 0) {
      navigate("/ucits/disclaimer", {
        state: { toUrl },
      });
    }
  }, [ucits]);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData({ loading: false, funds: res.data, error: false });
      })
      .catch((err) => setData({ loading: false, funds: null, error: true }));
  }, [setData]);

  const fund = data?.funds?.find((item) => item.fundName === pageContext.title);

  let info = data?.error ? (
    <p> Error fetching fund details </p>
  ) : (
    <Spinner verticalAdjustment="25px" />
  );

  if (data?.funds) {
    info = (
      <div className={gStyles.textBlock}>
        <h3>Share class unit prices</h3>
        <ul className={styles.shareList}>
          {fund?.shareClasses.map((item) => (
            <li>
              {item.description} {item.nav}{" "}
              {item?.valuationDate ? `(${item?.valuationDate.display})` : ""}
              <br></br>
              Benchmark: <em> {item.benchmark} </em>
            </li>
          ))}
        </ul>
        <h3>Investment objective</h3>
        <p>{fund?.investmentObjective}</p>
        {fund?.keyDocuments.length ? (
          <>
            <h3>Key documents </h3>
            <ul className={styles.docList}>
              {fund?.keyDocuments.map((item) => (
                <li>
                  <a
                    href={item.fileLocation}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.fileName}
                  </a>
                </li>
              ))}
              <li>
                <Link to="/best-execution-order-handling">
                  Best execution policy
                </Link>
              </li>
              <li>
                <Link to="/remuneration-policy">Remuneration policy</Link>
              </li>
            </ul>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <Layout>
      {ucits && ucits === 1 ? (
        <div className={gStyles.copyWrap}>
          <h1 className={`${gStyles.header} h2 `}>{pageContext.title}</h1>
          <div className={gStyles.content}>{info}</div>
        </div>
      ) : null}
    </Layout>
  );
}

export const query = graphql`
  {
    strapiUcits {
      mainCopy
    }
  }
`;
