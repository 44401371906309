import React from "react";
import Loader from "../../images/amx-loader.svg";
const styles = require("./Spinner.module.scss");

export const Spinner = (props) => {
  const { verticalAdjustment = "inital" } = props;
  return (
    <div className={styles.spinnerWrap}>
      <div className={`${styles.spinner}`} style={{ top: verticalAdjustment }}>
        <img src={Loader} alt="loading..." />
      </div>
    </div>
  );
};
